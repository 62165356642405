
import Loading from "@/components/Loading/index.vue";
import { ElMessage } from "element-plus";
import Api from "@/assets/api/api";
import { useStore } from "vuex";
import MyDialog from "@/components/Dialog/index.vue";
import { Plus } from "@element-plus/icons-vue";
import ParametricModel from "./components/ParametricModel.vue";
import NormalMode from "./components/NormalMode/index.vue";
import MySelect from "@/components/MySelect/index.vue";
import bus from "@/utils/bus";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  ref,
} from "vue";
export default defineComponent({
  name: "",
  components: { ParametricModel, NormalMode, MySelect, MyDialog, Loading },
  setup() {
    const store = useStore();
    const data = reactive({
      value: "",
      Plus: Plus,
      ruleForm: {
        itemName: "",

        projectId: store.state.projectId,
      },

      rules: {
        itemName: [
          {
            required: true,
            message: "工件名称不得为空",
            trigger: "blur",
          },
        ],
      },
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
    });
    const MyMyDialog = <any>ref();
    const ruleFormRef = <any>ref();
    const getDialog = () => {
      let Item = {
        name: "新建工件",
      };
      MyMyDialog.value.getShow(Item);
    };
    const getConfirm = () => {
      ruleFormRef._value.validate((valid: any, fields: any) => {
        if (valid) {
          Api.commonPost(
            "production",
            "CreateItem",
            data.ruleForm,
            (res: any) => {
              console.log(res);
              ElMessage({
                message: "新增成功",
                type: "success",
              });
              bus.emit("getList");
              MyMyDialog.value.getClose("close");
            },
            (err: any) => {
              ElMessage.error(err.msg);
            }
          );
        } else {
          console.log("error submit!", fields);
        }
      });
    };
    const getClose = () => {
      ruleFormRef._value.resetFields();
    };
    return {
      ...toRefs(data),
      getConfirm,
      getClose,
      MyMyDialog,
      ruleFormRef,
      getDialog,
    };
  },
});
