
import { ElMessage } from "element-plus";
import Api from "@/assets/api/api";
import { useStore } from "vuex";
import { Usable } from "@/assets/api/production/public";
import MyDialog from "@/components/Dialog/index.vue";
import MySelect from "@/components/MySelect/index.vue";
import PublicSelect from "@/components/PublicSelect/index.vue";
import ContentModule from "./ContentModule.vue";

import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  computed,
  ref,
  watch,
  nextTick,
  onUnmounted,
} from "vue";
import bus from "@/utils/bus";
import { fa } from "element-plus/es/locale";
export default defineComponent({
  name: "",

  components: { ContentModule, MyDialog, MySelect, PublicSelect },
  setup() {
    const store = useStore();
    const data = reactive({
      modelName: "",
      tabPosition: "left",
      typeData: {
        pathShow: true,
      },
      addTo: "addTo",
      Time: <any>"",
      btnList: [
        { id: 1, name: "同步全部PLC参数", button: "" },
        { id: 2, name: "模板引用", button: "" },
        { id: 3, name: "删除模式", button: "" },
        { id: 4, name: "禁用模式", button: "2" },
        { id: 5, name: "保存", button: "" },
      ],
      idShow: <any>"1",
      tabsList: [
        { id: 1, name: "加热炉" },
        { id: 2, name: "淬火机" },
        { id: 3, name: "抛瓦喷油" },
        { id: 4, name: "R1" },
        { id: 5, name: "R2" },
        { id: 6, name: "R3" },
        { id: 7, name: "R4" },
        { id: 8, name: "R5" },
      ],
      ButData: {
        button: 0,
        modelId: "",
        projectId: store.state.projectId,
      },
      // 模式参数
      patternData: <any>{
        modelId: store.state.modelId,
        projectId: store.state.projectId,
      },
      AllList: <any>{
        sendGroupList: [],
      },
      ModuleShow: true,
      Cut: "model",
      usableStatus: <any>"",
      optionsList: [],
      argumentsData: {
        projectId: store.state.projectId,
        produceItemCode: 1,
        findOnlyUsable: 0,
      },
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      bus.on("getEmpty", getEmpty);
      bus.on("getJobMode", getJobMode);
      setTimeout(() => {
        Getchange();
      }, 400);
    });

    onUnmounted(() => {
      bus.off("getEmpty");
      bus.off("getJobMode");
    });

    const Jobmode = () => {
      nextTick(() => {
        getModel();
      });
    };

    // 工件模式
    const getModel = () => {
      Usable(data.argumentsData, usableBack, data.typeData);
    };

    const usableBack = (Data: any) => {
      data.optionsList = Data.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      if (Data.length <= 0) {
        PublicSelect.value.getValue();
      }
    };

    let usableStatus = computed(() => {
      return store.state.Production.usableStatus;
    });
    let ProductionItemCode = computed(() => {
      return store.state.Production.ProductionItemCode;
    });
    watch(
      ProductionItemCode,
      (newValue) => {
        data.argumentsData.produceItemCode = newValue;
        data.patternData.itemCode = newValue;
      },
      { deep: true }
    );
    watch(
      usableStatus,
      (newValue, oldValue) => {
        data.usableStatus = newValue;
        Getchange();
      },
      { deep: true }
    );
    // 当没有工作模式的时候界面清空
    const getEmpty = () => {
      data.tabsList = [];
      data.AllList = {
        sendGroupList: [],
      };
    };

    // 监听值变化渲染
    const Getchange = () => {
      let name = "";
      name = data.usableStatus == 0 ? "可用模式" : "禁用模式";
      data.ButData.button = data.usableStatus == "0" ? 1 : 2;
      data.btnList[3].name = name;
    };

    const MyMyDialog = <any>ref();
    const MySelect = ref();
    const PublicSelect = ref();
    const getDialog = (Item: any) => {
      if (Item.id == 5) {
        getSave();
        return;
      }
      data.idShow = Item.id;
      MyMyDialog.value.getShow(Item);
    };
    const getSave = () => {
      Api.commonPost(
        "production",
        "ModelUpdate",
        {
          config: data.AllList,
          modelId: store.state.modelId,
          projectId: store.state.projectId,
        },
        (res: any) => {
          if (res.code == 0) {
            ElMessage({
              message: "保存成功",
              type: "success",
            });
          }
        },
        (err: any) => {
          ElMessage.error(err.msg);
        }
      );
    };
    const getJobMode = (Data: any) => {
      if (Data == "left") {
        data.patternData.modelId = store.state.modelId;
      }
      Api.commonPost(
        "production",
        "JobMode",
        data.patternData,
        (res: any) => {
          if (res.code == 0) {
            data.AllList = res.data;
            console.log("??????触发了", data.AllList);
            data.modelName = data.AllList.modelName;
            if (Data == "model") {
              empty();
            }
          }
        },
        (err: any) => {
          ElMessage.error(err.msg);
        }
      );
      data.Time = new Date().getTime();
    };

    const getConfirm = () => {
      if (data.idShow == 4 || data.idShow == 3) {
        data.ButData.modelId = store.state.modelId;
        if (data.idShow == 3) data.ButData.button = 3;
        Api.commonPost(
          "production",
          "ModelButton",
          data.ButData,
          (res: any) => {
            if (res.code == 0) {
              ElMessage({
                message: res.msg,
                type: "success",
              });
              bus.emit("WorkPattern");

              MyMyDialog.value.getClose("close");
            }
          },
          (err: any) => {
            ElMessage.error(err.msg);
          }
        );
      }
      // 模板引用
      if (data.idShow == 2) {
        if (!data.patternData.itemCode) {
          ElMessage.error("请选择工件类型");
          return;
        }
        if (!data.patternData.modelId) {
          ElMessage.error("请选择工件模式");
          return;
        }
        getJobMode("model");
        MyMyDialog.value.getClose("close");
      }
    };

    const getClose = () => {
      empty();
    };
    const getOption = (e: any) => {
      console.log(e);
      data.patternData.modelId = e;
    };

    const empty = () => {
      store.commit("SET_ProductionItemCode", "");
      data.patternData.itemCode = "";
      data.patternData.modelId = "";
      store.state.Production.ProductionItemCode;
      PublicSelect.value.getValue();
      MySelect.value.getValue();
    };

    // 获取
    return {
      ...toRefs(data),
      MyMyDialog,
      MySelect,
      PublicSelect,
      getDialog,
      getClose,
      getConfirm,
      getEmpty,
      Jobmode,
      getOption,
    };
  },
});
