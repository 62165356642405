
import { ElMessage } from "element-plus";
import Api from "@/assets/api/api";
import { useStore } from "vuex";
import MyDialog from "@/components/Dialog/index.vue";
import { Usable } from "@/assets/api/production/public";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  ref,
  onUnmounted,
  computed,
  watch,
  nextTick,
} from "vue";
import bus from "@/utils/bus";
export default defineComponent({
  name: "",
  components: { MyDialog },
  setup() {
    const store = useStore();
    const data = reactive({
      ruleForm: {
        modelName: "",
        produceItemCode: store.state.itemCode,
        projectId: store.state.projectId,
      },
      list: <any>[
        {
          id: 1,
          name: "正常模式",
          typeName: "可用",
          color: "green",
          bgc: true,
        },
      ],
      argumentsData: {
        projectId: store.state.projectId,
        produceItemCode: store.state.itemCode,
        findOnlyUsable: 0,
      },
      rules: {
        modelName: [
          {
            required: true,
            message: "工作模式名称不得为空",
            trigger: "blur",
          },
        ],
      },
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      bus.on("WorkPattern", getList);
      nextTick(() => {
        getList();
      });
    });
    onUnmounted(() => {
      bus.off("WorkPattern");
    });
    let itemCode = computed(() => {
      return store.state.itemCode;
    });

    watch(
      itemCode,
      (newValue) => {
        data.ruleForm.produceItemCode = newValue;
        data.argumentsData.produceItemCode = newValue;
        getList();
      },
      { deep: true }
    );

    const getList = () => {
      Usable(data.argumentsData, usableBack, {});
    };
    const usableBack = (Data: any) => {
      data.list = Data;
      if (data.list.length) {
        store.commit("SET_usableStatus", data.list[0].usableStatus);
        bus.emit("getJobMode", "left");
      } else {
        bus.emit("getEmpty");
      }
    };
    const MyMyDialog = <any>ref();
    const ruleFormRef = <any>ref();
    const getDialog = () => {
      let Item = {
        name: "新增工作模式",
      };
      console.log(MyMyDialog);
      MyMyDialog.value.getShow(Item);
    };
    const getConfirm = () => {
      ruleFormRef._value.validate((valid: any, fields: any) => {
        if (valid) {
          Api.commonPost(
            "production",
            "ModelNew",
            data.ruleForm,
            (res: any) => {
              console.log(res);
              ElMessage({
                message: "新增成功",
                type: "success",
              });
              getList();
              MyMyDialog.value.getClose("close");
            },
            (err: any) => {
              ElMessage.error(err.msg);
            }
          );
        } else {
          console.log("error submit!", fields);
        }
      });
    };
    const getClose = () => {
      ruleFormRef._value.resetFields();
    };
    const getCut = (Data: any) => {
      data.list.forEach((item: any) =>
        item.id == Data.id ? (item.bgc = true) : (item.bgc = false)
      );
      store.commit("SET_modelId", Data.id);
      store.commit("SET_usableStatus", Data.usableStatus);
      bus.emit("getJobMode", "left");
    };
    return {
      ...toRefs(data),
      MyMyDialog,
      getDialog,
      ruleFormRef,
      getConfirm,
      getClose,
      getCut,
    };
  },
});
