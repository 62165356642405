
import { useRouter } from "vue-router";
import { Usable } from "@/assets/api/production/public";
import { ElMessage } from "element-plus";
import Api from "@/assets/api/api";
import bus from "@/utils/bus";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  onUnmounted,
  watch,
  computed,
} from "vue";

import { useStore } from "vuex";
export default defineComponent({
  name: "",
  props: {
    addTo: {
      type: String,
      default: "",
    },
    Cut: {
      type: String,
      default: "",
    },
    typeData: {
      type: Object,
    },
  },
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const data = reactive({
      // value: "484138129905680384",
      value: "",
      options: [
        {
          itemName: "测试数据",
          itemCode: "测试数据",
        },
      ],

      Cut: "",
      pathShow: "",
    });

    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted

      bus.on("getList", getList);

      getList();

      // 回显值
      setTimeout(() => {
        data.pathShow = props.typeData?.pathShow;
      });
    });
    onUnmounted(() => {
      bus.off("getList");
    });

    // 工件下拉列表
    const getList = () => {
      Api.commonPost(
        "production",
        "workpieceList",
        { projectId: store.state.projectId },
        (res: any) => {
          data.options = res.data;
          if (props.addTo) {
            data.options.push({
              itemName: "无工件",
              itemCode: <any>0,
            });
          }
          if (!props.Cut) {
            data.value = data.options[0].itemCode;
          }
          store.commit("SET_itemCode", data.options[0].itemCode);
        },
        (err: any) => {
          console.log(err);
          ElMessage.error(err.msg);
        }
      );
    };
    const getCut = (e: any) => {
      if (data.pathShow) {
        // 当界面处于生产管理的时候 就换个存储
        store.commit("SET_ProductionItemCode", e);
        context.emit("Jobmode"); // 当切换工件类型时候调用  工件模式
        return;
      }
      store.commit("SET_itemCode", e);
    };
    const getValue = () => {
      data.value = "";
    };
    return {
      ...toRefs(data),
      getCut,
      getValue,
    };
  },
});
