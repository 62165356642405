
import MyDialog from "@/components/Dialog/index.vue";
import bus from "@/utils/bus";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from "vue";
export default defineComponent({
  name: "",
  props: {
    setupList: {
      type: Array,
    },
  },
  components: { MyDialog },
  setup(props) {
    const data = reactive({
      list: <any>[
        {
          id: 1,
          name1: "步进间隔",
          name2: "设定值:",
          input1: "",
          value1: "",
          kind: "秒",
          checked1: true,
          Warning: "告警提示",
        },
        {
          id: 2,
          name1: "T1温度",
          name2: "设定值:",
          input1: "",
          value1: "",
          kind: "秒",
          checked1: true,
          Warning: "告警提示",
          earlyWarning: "橙色预警:",
          earlyWarningValue: "",
          kind2: "°C",
          kind3: "°C",
          lowerLimit: "",
          warning: "红色警告",
          red1: "",
          red2: "",
        },
        {
          id: 3,
          name1: "T2温度",
          name2: "设定值:",
          input1: "",
          value1: "",
          kind: "秒",
          checked1: true,
          Warning: "告警提示",
          earlyWarning: "橙色预警:",
          earlyWarningValue: "",
          kind2: "°C",
          kind3: "°C",
          lowerLimit: "",
          warning: "红色警告",
          red1: "",
          red2: "",
        },
        {
          id: 4,
          name1: "T3温度",
          name2: "设定值:",
          input1: "",
          value1: "",
          kind: "秒",
          checked1: true,
          Warning: "告警提示",
          earlyWarning: "橙色预警:",
          earlyWarningValue: "",
          kind2: "°C",
          kind3: "°C",
          lowerLimit: "",
          warning: "红色警告",
          red1: "",
          red2: "",
        },
      ],
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      getDetail();
    });
    const getDetail = () => {
      data.list = [];

      setTimeout(() => {
        props.setupList?.forEach((item: any, i: any) => {
          // data.list.push({
          //   id: i + 1,
          //   keyName: item.keyName,
          //   name2: "设定值:",
          //   input1: "",
          //   value1: "",
          //   kind: "秒",
          //   checked1: true,
          //   Warning: "告警提示",
          //   earlyWarning: "橙色预警:",
          //   alertMaxLevel2: item.alertMaxLevel2,
          //   alertMinLevel2: item.alertMaxLevel2,
          //   kind2: "°C",
          //   kind3: "°C",
          //   lowerLimit: "",
          //   warning: "红色警告",
          //   alertMaxLevel: item.alertMaxLevel,
          //   alertMinLevel: item.alertMinLevel,
          // });
          item.name2 = "设定值:";
          item.id = i + 1;
          item.name2 = "设定值:";
          item.kind = "秒";
          item.Warning = "告警提示";
          item.earlyWarning = "橙色预警:";
          item.kind2 = "°C";
          item.kind3 = "°C";
          item.warning = "红色警告";
        });
        console.log(props.setupList?.length);
      }, 100);
    };

    watch(
      props,
      (newValue) => {
        getinitialize(newValue);
      },
      { deep: true }
    );
    const getinitialize = (Data: any) => {
      Data.setupList?.forEach((item: any, i: any) => {
        // data.list.push({
        //   id: i + 1,
        //   keyName: item.keyName,
        //   name2: "设定值:",
        //   input1: "",
        //   value1: "",
        //   kind: "秒",
        //   checked1: true,
        //   Warning: "告警提示",
        //   earlyWarning: "橙色预警:",
        //   alertMaxLevel2: item.alertMaxLevel2,
        //   alertMinLevel2: item.alertMaxLevel2,
        //   kind2: "°C",
        //   kind3: "°C",
        //   lowerLimit: "",
        //   warning: "红色警告",
        //   alertMaxLevel: item.alertMaxLevel,
        //   alertMinLevel: item.alertMinLevel,
        // });
        item.name2 = "设定值:";
        item.id = i + 1;
        item.name2 = "设定值:";
        item.kind = "°C";
        item.Warning = "告警提示";
        item.earlyWarning = "橙色预警:";
        item.kind2 = "°C";
        item.kind3 = "°C";
        item.warning = "红色警告";
      });
    };

    const MyMyDialog = <any>ref();
    const getDialog = (Item: any) => {
      MyMyDialog.value.getShow(Item);
    };

    const getClose = () => {
      MyMyDialog.value.getClose();
    };
    return {
      ...toRefs(data),
      MyMyDialog,
      getDialog,
      getClose,
    };
  },
});
